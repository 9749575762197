<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-card class="mt-4">
        <v-card-title>
          Dashboard
          <v-spacer></v-spacer>
        </v-card-title>
      </v-card>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-row dense class="mt-4">
        <v-col cols="12" sm="3" md="3">
          <v-card color="#17A2B8" dark>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="text-h5"
                  v-text="totalKegiatanTitle"
                ></v-card-title>

                <v-card-subtitle
                  v-text="totalKegiatanSubtitle"
                ></v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    class="ml-2 mt-5"
                    outlined
                    rounded
                    small
                    @click="changeWaktu(0)"
                  >
                    Lihat
                  </v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-card color="#28A745" dark>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="text-h5"
                  v-text="kegiatanBerlangsungTitle"
                ></v-card-title>

                <v-card-subtitle
                  v-text="kegiatanBerlangsungSubtitle"
                ></v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    class="ml-2 mt-5"
                    outlined
                    rounded
                    small
                    @click="changeWaktu(1)"
                  >
                    Lihat
                  </v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-card color="#FFC107" dark>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="text-h5"
                  v-text="kegiatanSelesaiTitle"
                ></v-card-title>

                <v-card-subtitle
                  v-text="kegiatanSelesaiSubtitle"
                ></v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    class="ml-2 mt-5"
                    outlined
                    rounded
                    small
                    @click="changeWaktu(2)"
                  >
                    Lihat
                  </v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-card color="#DC3545" dark>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="text-h5"
                  v-text="kegiatanAkanDatangTitle"
                ></v-card-title>

                <v-card-subtitle
                  v-text="kegiatanAkanDatangSubtitle"
                ></v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    class="ml-2 mt-5"
                    outlined
                    rounded
                    small
                    @click="changeWaktu(3)"
                  >
                    Lihat
                  </v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-card class="pt-4 pl-4">
            <v-chart
              class="chart"
              :option="jumlahPesertaKegiatanChart"
              theme="ovilia-green"
              autoresize
            /> </v-card
        ></v-col>
        <v-col cols="12" sm="6" md="6">
          <v-card class="pt-4 pl-4">
            <v-chart
              class="chart"
              :option="kategoriKegiatanDataChart"
            /> </v-card
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-card class="pt-4 pl-4 pb-4">
            <div v-for="(item, i) in jumlahPesertaKegiatanName" :key="i">
              <span style="font-weight:8px;"
                >{{ item }} [{{ jumlahPesertaKegiatanData[i] }}] :
                {{ jumlahPesertaKegiatanNameLegend[i] }}</span
              >
            </div>
          </v-card></v-col
        >
      </v-row>
      <v-card class="mt-4">
        <v-tabs v-model="tab">
          <v-tab>Kegiatan</v-tab>
          <v-tab>Registrasi Peserta Kegiatan</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card>
              <v-card-title>
                Kegiatan
                <v-spacer></v-spacer>
                <v-combobox
                  label="Kategori"
                  clearable
                  dense
                  outlined
                  v-model="selectedKategori"
                  :items="items_kategori"
                  item-text="value"
                  @change="getDataFromApi"
                ></v-combobox>
                <v-spacer></v-spacer>
                <v-combobox
                  label="Waktu"
                  clearable
                  dense
                  outlined
                  v-model="selectedWaktu"
                  :items="items_waktu"
                  item-text="value"
                  @change="getDataFromApi"
                ></v-combobox>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="list_data"
                :options.sync="options"
                :server-items-length="totalData"
                :loading="loading"
                class="elevation-1 scroll-to-me"
              >
                <template v-slot:[`item.tanggal_awal`]="{ item }">
                  {{ getCurrentDate(item.tanggal_awal) }}
                </template>
                <template v-slot:[`item.tanggal_akhir`]="{ item }">
                  {{ getCurrentDate(item.tanggal_akhir) }}
                </template>
                <template v-slot:[`item.harga`]="{ item }">
                  {{ new Intl.NumberFormat("id-ID").format(item.harga) }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon medium class="mr-3" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <PelatihanPesertaComponent />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import KegiatanService from "../services/KegiatanService";
import DashboardService from "../services/DashboardService";
import * as moment from "moment";
import MasterService from "../services/MasterService";
import PelatihanPesertaComponent from "./dashboard/PelatihanPesertaDashboard.vue";

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default {
  components: {
    VChart,
    PelatihanPesertaComponent,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  data: () => ({
    tab: null,
    totalKegiatanTitle: 0,
    totalKegiatanSubtitle: "Jumlah Kegiatan",
    kegiatanBerlangsungTitle: 0,
    kegiatanBerlangsungSubtitle: "Kegiatan Berlangsung",
    kegiatanSelesaiTitle: 0,
    kegiatanSelesaiSubtitle: "Kegiatan Selesai",
    kegiatanAkanDatangTitle: 0,
    kegiatanAkanDatangSubtitle: "Kegiatan akan Datang",
    breadcrumbs: [
      {
        text: "Data Dashboard",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Judul", value: "kegiatan_title", sortable: false },
      { text: "Kategori", value: "kategori_name", sortable: false },
      { text: "Tanggal Awal", value: "tanggal_awal", sortable: false },
      { text: "Tanggal Akhir", value: "tanggal_akhir", sortable: false },
      { text: "Harga", value: "harga", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    kategoriKegiatanName: [],
    kategoriKegiatanData: [],
    jumlahPesertaKegiatanName: [],
    jumlahPesertaKegiatanData: [],
    jumlahPesertaKegiatanNameLegend: [],
    selectedKategori: "",
    selectedWaktu: "",
    items_kategori: [],
    items_waktu: [
      {
        code: 1,
        value: "Kegiatan Berlangsung",
      },
      {
        code: 2,
        value: "Kegiatan Selesai",
      },
      {
        code: 3,
        value: "Kegiatan akan Datang",
      },
    ],
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
    this.getDashboard();
    this.getListKategoriKegiatan();
  },
  computed: {
    kategoriKegiatanDataChart() {
      return {
        title: {
          text: "Jumlah Kegiatan per Kategori",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: this.kategoriKegiatanName,
        },
        series: [
          {
            name: "Jumlah Kegiatan",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: this.kategoriKegiatanData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    jumlahPesertaKegiatanChart() {
      return {
        title: {
          text: "Jumlah Peserta 5 Kegiatan Terakhir",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c}",
        },
        xAxis: {
          data: this.jumlahPesertaKegiatanName,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            type: "bar",
            data: this.jumlahPesertaKegiatanData,
          },
        ],
        color: ["#25D6BB"],
      };
    },
  },
  methods: {
    changeWaktu(val) {
      const el = this.$el.getElementsByClassName("scroll-to-me")[0];

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: "smooth" });
      }

      this.items_waktu.forEach((entry) => {
        if (entry.code == val) {
          this.selectedWaktu = entry;
        }
      });

      this.getDataFromApi();
    },
    random() {
      return Math.round(300 + Math.random() * 700) / 10;
    },
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      console.log(this.selectedKategori);

      var param = null;
      var paramWaktu = null;

      if (this.selectedKategori) {
        param = this.selectedKategori.code;
      }

      if (this.selectedWaktu) {
        paramWaktu = this.selectedWaktu.code;
      }

      KegiatanService.kegiatanListByKategori(
        page,
        itemsPerPage,
        this.search,
        param,
        paramWaktu
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    getDashboard() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      DashboardService.getDashboard(page, itemsPerPage, this.search)
        .then((res) => {
          this.totalKegiatanTitle = res.data.total_kegiatan;
          this.kegiatanBerlangsungTitle = res.data.total_kegiatan_berlangsung;
          this.kegiatanSelesaiTitle = res.data.total_kegiatan_selesai;
          this.kegiatanAkanDatangTitle = res.data.total_kegiatan_akan_datang;

          this.kategoriKegiatanName = res.data.kategori_kegiatan;
          this.kategoriKegiatanData = res.data.kategori_kegiatan_data;

          this.jumlahPesertaKegiatanName = [];
          this.jumlahPesertaKegiatanData = [];

          const alphabet = ["A", "B", "C", "D", "E"];

          console.log(res.data.list_kegiatan_terakhir_name);
          var indexKegiatan = 0;
          res.data.list_kegiatan_terakhir_name.forEach((entry) => {
            this.jumlahPesertaKegiatanName.push(alphabet[indexKegiatan++]);
            this.jumlahPesertaKegiatanData.push(entry.value);
            this.jumlahPesertaKegiatanNameLegend.push(entry.kegiatan_title);
          });

          this.kegiatanKategoriKey++;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    async getListKategoriKegiatan() {
      MasterService.kategoriKegiatanList()
        .then((res) => {
          this.items_kategori = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/kegiatan-edit/" + item.kegiatan_id).catch((err) => {
        console.log(err);
      });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      KegiatanService.delete(this.editedItem.kegiatan_id)
        .then((res) => {
          console.log(res);
          this.$toast.success("Hapus kegiatan berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Hapus kegiatan gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
