import http from "../http-common";
/* eslint-disable */
class PelatihanDetailService {
  pelatihanDetailList(page, itemPerPage, keyword, pelatihanCategoryId) {
    return http.get(
      "/pelatihan-detail/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&pelatihan_category_id=" +
        pelatihanCategoryId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();

    if (data.pelatihan_category_id)
      bodyFormData.append("pelatihan_category_id", data.pelatihan_category_id);
    if (data.title) bodyFormData.append("title", data.title);
    if (data.urutan) bodyFormData.append("urutan", data.urutan);
    if (data.video) bodyFormData.append("video", data.video);
    if (data.description) bodyFormData.append("description", data.description);
    if (data.tanggal_pretest)
      bodyFormData.append("tanggal_pretest", data.tanggal_pretest);
    if (data.jam_awal_pretest)
      bodyFormData.append("jam_awal_pretest", data.jam_awal_pretest);
    if (data.jam_akhir_pretest)
      bodyFormData.append("jam_akhir_pretest", data.jam_akhir_pretest);
    if (data.tanggal_posttest)
      bodyFormData.append("tanggal_posttest", data.tanggal_posttest);
    if (data.jam_awal_posttest)
      bodyFormData.append("jam_awal_posttest", data.jam_awal_posttest);
    if (data.jam_akhir_posttest)
      bodyFormData.append("jam_akhir_posttest", data.jam_akhir_posttest);

    if (data.selectedKategori && data.selectedKategori != undefined) {
      bodyFormData.append("kategori", data.selectedKategori.code);
    }

    if (data.file_pdf != null) bodyFormData.append("file", data.file_pdf);

    return http.post("/pelatihan-detail", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();

    bodyFormData.append("id", data.id);
    if (data.pelatihan_category_id)
      bodyFormData.append("pelatihan_category_id", data.pelatihan_category_id);
    if (data.title) bodyFormData.append("title", data.title);
    if (data.urutan) bodyFormData.append("urutan", data.urutan);
    if (data.video) bodyFormData.append("video", data.video);
    if (data.description) bodyFormData.append("description", data.description);
    if (data.tanggal_pretest)
      bodyFormData.append("tanggal_pretest", data.tanggal_pretest);
    if (data.jam_awal_pretest)
      bodyFormData.append("jam_awal_pretest", data.jam_awal_pretest);
    if (data.jam_akhir_pretest)
      bodyFormData.append("jam_akhir_pretest", data.jam_akhir_pretest);
    if (data.tanggal_posttest)
      bodyFormData.append("tanggal_posttest", data.tanggal_posttest);
    if (data.jam_awal_posttest)
      bodyFormData.append("jam_awal_posttest", data.jam_awal_posttest);
    if (data.jam_akhir_posttest)
      bodyFormData.append("jam_akhir_posttest", data.jam_akhir_posttest);

    if (data.selectedKategori && data.selectedKategori != undefined) {
      bodyFormData.append("kategori", data.selectedKategori.code);
    }

    if (data.file_pdf != null) bodyFormData.append("file", data.file_pdf);

    return http.put(`/pelatihan-detail/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/pelatihan-detail/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export default new PelatihanDetailService();
