<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app v-if="$route.meta.isHome === 1">
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-avatar color="primary" size="56">
              <v-img :src="currentUser.photo"></v-img>
            </v-avatar>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ currentUser.nama }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>
        <router-link
          to="/dashboard"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link
          to="/kegiatan"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Pelatihan</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link to="/nakes" style="text-decoration: none; color: inherit">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>User</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link
          to="/timeline"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Timeline Mobile</v-list-item-title>
          </v-list-item>
        </router-link>

        <v-list-group no-action>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Master</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/bank"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Bank</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/profesi"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Profesi</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/komunitas"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Komunitas</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/evaluasi-fasilitator"
                style="text-decoration: none; color: inherit;"
              >
                <v-list-item-title>Evaluasi Fasilitator</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/master/jabatanlist"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Jabatan</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/layanan"
                style="text-decoration: none; color: inherit;"
              >
                <v-list-item-title>Layanan</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/wilayah"
                style="text-decoration: none; color: inherit;"
              >
                <v-list-item-title>Wilayah</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/profesi"
                style="text-decoration: none; color: inherit;"
              >
                <v-list-item-title>Profesi</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/spesialisasi"
                style="text-decoration: none; color: inherit;"
              >
                <v-list-item-title>Spesialisasi</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/faskes"
                style="text-decoration: none; color: inherit;"
              >
                <v-list-item-title>Faskes</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/faskes-category"
                style="text-decoration: none; color: inherit;"
              >
                <v-list-item-title>Faskes Kategori</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item> -->
        </v-list-group>
        <v-list-group no-action>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Web Content</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/sejarah"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Sejarah</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/visi-misi"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Visi Misi</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/struktur-organisasi"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Struktur Organisasi</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/alur-pendaftaran"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Alur Pendaftaran Peserta</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/index-kepuasan"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Index Kepuasan</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/fasilitator"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Fasilitator</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/berita"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Berita</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/testimoni"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Testimoni</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/fasilitas"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Fasilitas</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/direksi"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Direksi</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/agenda-pelatihan"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Agenda Pelatihan</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/jadwalpelatihanlist"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Jadwal Pelatihan</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/brosurlist"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Brosur</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <router-link to="/" style="text-decoration: none; color: inherit">
          <v-list-item link @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </router-link>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar color="purple" dark app dense v-if="$route.meta.isHome === 1">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Pusat Pengembangan Kesehatan Carolus</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-badge color="green" dot>
        <v-icon medium> mdi-vuetify </v-icon>
      </v-badge>
    </v-app-bar>

    <router-view></router-view>

    <!-- <v-footer
      padless
      color="purple"
      v-if="$route.meta.isHome === 1"
    >
      <v-col
        class="text-center white--text"
        cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>Halo Medika</strong>
      </v-col>
    </v-footer> -->
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: null,
    admins: [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
    ],
    cruds: [
      ["Create", "mdi-plus-outline"],
      ["Read", "mdi-file-outline"],
      ["Update", "mdi-update"],
      ["Delete", "mdi-delete"],
    ],
    currentUser: {
      nama: "",
      photo: "",
      email: "",
    },
  }),

  mounted() {
    var myUser = JSON.parse(
      localStorage.getItem(process.env.VUE_APP_USER_PREFIX)
    );

    if (myUser) {
      this.currentUser = myUser;
    }
  },

  methods: {
    logout: async function () {
      this.$store.dispatch("logout").then(() =>
        this.$router.push("/").catch((err) => {
          console.log(err);
        })
      );
    },
  },
};
</script>
