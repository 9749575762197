import http from "../http-common";
/* eslint-disable */
class SertifikatService {
  getSertifikat(id) {
    return http.get(`/pelatihan/sertifikat/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();
    // bodyFormData.append("posisi_nama_x", data.posisi_nama_x);
    bodyFormData.append("posisi_nama_y", data.posisi_nama_y);
    bodyFormData.append("kegiatan_id", data.kegiatan_id);
    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto);

    return http.post("/pelatihan/sertifikat", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    // bodyFormData.append("posisi_nama_x", data.posisi_nama_x);
    bodyFormData.append("posisi_nama_y", data.posisi_nama_y);
    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto);

    return http.put(`/pelatihan/sertifikat/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/kegiatan/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  downloadSertifikat(id) {
    return http.get(`/pelatihan/sertifikat/download/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new SertifikatService();
