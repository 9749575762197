<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title>
          Pers
        </v-card-title>
        <form class="pl-4 pr-4">
          <v-row dense>
            <v-col
              cols="12"
              sm="4"
              md="4"
              class="pr-4"
            >
              <v-sheet
                color="white"
                elevation="2"
              >
                <v-responsive :aspect-ratio="16/9">

                  <v-img
                    :key="fotoKey"
                    :src="pers.FotoUrl"
                    contain
                  ></v-img>

                </v-responsive>
              </v-sheet>
              <v-file-input
                @change="loadImage"
                accept="image/*"
                v-model="pers.file_foto"
                prepend-icon="mdi-image"
                show-size
                label="Foto"
              />
            </v-col>
            <v-col
              cols="12"
              sm="8"
              md="8"
            >
              <v-row dense>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    label="Judul"
                    outlined
                    dense
                    v-model="pers.judul"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-textarea
                    outlined
                    label="Konten"
                    v-model="pers.konten"
                    rows="12"
                    row-height="15"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="d-flex justify-end"
            >
              <v-btn
                @click="doBatal"
                style="margin-right: 10px;"
              >Batal</v-btn>
              <v-btn
                dark
                color="primary"
                type="button"
                @click="doSubmit"
              >Simpan</v-btn>
            </v-col>
          </v-row>
        </form>
      </v-card>

    </v-container>
  </v-main>
</template>

<script>
import PersService from "../services/PersService";

export default {
  name: "PersAdd",
  components: {},
  data: () => ({
    breadcrumbs: [
      {
        text: "Data Pers",
        disabled: false,
        exact: true,
        to: { name: "Pers" },
      },
      { text: "Tambah Pers", disabled: true },
    ],
    pers: {
      FotoUrl: "",
      file_foto: null,
      judul: "",
      konten: "",
    },
    result: {},
    fotoKey: 0,
  }),
  mounted: async function () {},
  computed: {
    imageSrc() {
      return this.pers.FotoUrl;
    },
  },
  methods: {
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.pers.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.pers.FotoUrl = "";
        this.pers.file_foto = null;
      }
    },

    doBatal: function () {
      this.$router.go(-1);
    },

    doSubmit: async function () {
      PersService.create(this.pers, this.pers.file_foto)
        .then((res) => {
          console.log(res);
          this.$toast.success("Tambah Pers berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.$router.push("/pers-edit/" + res.data.result.id).catch((err) => {
            console.log(err);
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Tambah pers gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
  },
};
</script>

<style scoped>
</style>
