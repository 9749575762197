import http from "../../http-common";
/* eslint-disable */
class IndexKepuasanService {
  indexKepuasan() {
    return http.get("/index-kepuasan", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();

    if (data.periode_dari)
      bodyFormData.append("periode_dari", data.periode_dari);

    if (data.periode_sampai)
      bodyFormData.append("periode_sampai", data.periode_sampai);

    if (data.nilai) bodyFormData.append("nilai", data.nilai);

    if (data.nilai_sebut) bodyFormData.append("nilai_sebut", data.nilai_sebut);

    if (data.jumlah_responden)
      bodyFormData.append("jumlah_responden", data.jumlah_responden);

    return http.post("/index-kepuasan", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);

    if (data.periode_dari)
      bodyFormData.append("periode_dari", data.periode_dari);

    if (data.periode_sampai)
      bodyFormData.append("periode_sampai", data.periode_sampai);

    if (data.nilai) bodyFormData.append("nilai", data.nilai);

    if (data.nilai_sebut) bodyFormData.append("nilai_sebut", data.nilai_sebut);

    if (data.jumlah_responden)
      bodyFormData.append("jumlah_responden", data.jumlah_responden);

    return http.put(`/index-kepuasan/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new IndexKepuasanService();
