import http from "../http-common";
/* eslint-disable */
class RecordActivityService {
  statusVideoPesertaReportList(
    page,
    itemPerPage,
    kegiatanId,
    userId,
    namaPeserta,
    status
  ) {
    return http.get(
      "/record-activity/pelatihan/pelatihan-video/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&kegiatan_id=" +
        kegiatanId +
        "&user_id=" +
        userId +
        "&peserta_nama=" +
        namaPeserta +
        "&status=" +
        status,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  evaluasiNarasumberReportList(
    page,
    itemPerPage,
    kegiatanId,
    userId,
    narasumberId,
    namaNarasumber,
    namaPeserta,
    status
  ) {
    return http.get(
      "/record-activity/pelatihan/evaluasi-fasilitator/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&kegiatan_id=" +
        kegiatanId +
        "&user_id=" +
        userId +
        "&narasumber_id=" +
        narasumberId +
        "&narasumber_nama=" +
        namaNarasumber +
        "&peserta_nama=" +
        namaPeserta +
        "&status=" +
        status,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  evaluasiPenyelenggaraanReportList(
    page,
    itemPerPage,
    kegiatanId,
    userId,
    namaPeserta,
    status
  ) {
    return http.get(
      "/record-activity/pelatihan/evaluasi-penyelenggaraan/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&kegiatan_id=" +
        kegiatanId +
        "&user_id=" +
        userId +
        "&peserta_nama=" +
        namaPeserta +
        "&status=" +
        status,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  evaluasiPascaKegiatanReportList(
    page,
    itemPerPage,
    kegiatanId,
    userId,
    namaPeserta,
    status
  ) {
    return http.get(
      "/record-activity/pelatihan/evaluasi-pasca-kegiatan/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&kegiatan_id=" +
        kegiatanId +
        "&user_id=" +
        userId +
        "&peserta_nama=" +
        namaPeserta +
        "&status=" +
        status,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  evaluasiRTLReportList(
    page,
    itemPerPage,
    kegiatanId,
    userId,
    namaPeserta,
    status
  ) {
    return http.get(
      "/record-activity/pelatihan/evaluasi-rtl/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&kegiatan_id=" +
        kegiatanId +
        "&user_id=" +
        userId +
        "&peserta_nama=" +
        namaPeserta +
        "&status=" +
        status,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }
}

export default new RecordActivityService();
