import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import Nakes from "../views/Nakes.vue";
import Dashboard from "../views/Dashboard.vue";
import Kegiatan from "../views/Kegiatan.vue";
import KegiatanAdd from "../views/KegiatanAdd.vue";
import KegiatanEdit from "../views/KegiatanEdit.vue";
import Report from "../views/report/Report.vue";
import AgendaPelatihan from "../views/webcontent/AgendaPelatihan.vue";

import NakesAdd from "../views/NakesAdd.vue";
import NakesEdit from "../views/NakesEdit.vue";
import User from "../views/User.vue";
import UserAdd from "../views/UserAdd.vue";
import UserEdit from "../views/UserEdit.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import PrivacyMobile from "../views/PrivacyMobile.vue";
import Login from "../views/Login.vue";
import Profesi from "../views/Profesi.vue";
import Faskes from "../views/Faskes.vue";
import FaskesNakes from "../views/FaskesNakes.vue";
import FaskesCategory from "../views/FaskesCategory.vue";
import Chats from "../views/Chats.vue";
import ChatsList from "../views/ChatsList.vue";
import TentangKami from "../views/TentangKami.vue";
import KontakKami from "../views/KontakKami.vue";
import SyaratKetentuan from "../views/SyaratKetentuan.vue";
import Privacy from "../views/Privacy.vue";
import Bantuan from "../views/Bantuan.vue";
import Layanan from "../views/Layanan.vue";
import Spesialisasi from "../views/Spesialisasi.vue";
import Wilayah from "../views/Wilayah.vue";
import Kabupaten from "../views/Kabupaten.vue";
import Pers from "../views/Pers.vue";
import PersAdd from "../views/PersAdd.vue";
import PersEdit from "../views/PersEdit.vue";
import Bank from "../views/Bank.vue";
import EvaluasiFasilitator from "../views/EvaluasiFasilitator.vue";

//web content
import BrosurList from "../views/webcontent/BrosurList.vue";
import JadwalPelatihanList from "../views/webcontent/JadwalPelatihanList.vue";
import Jabatan from "../views/master/Jabatan.vue";
import Direksi from "../views/webcontent/DireksiList.vue";
import Berita from "../views/webcontent/BeritaList.vue";
import Testimoni from "../views/webcontent/TestimoniList.vue";
import Fasilitas from "../views/webcontent/FasilitasList.vue";
import Fasilitator from "../views/webcontent/FasilitatorList.vue";
import StrukturOrganisasi from "../views/webcontent/StrukturOrganisasi.vue";
import VisiMisi from "../views/webcontent/VisiMisi.vue";
import AlurPendaftaran from "../views/webcontent/AlurPendaftaran.vue";
import IndexKepuasan from "../views/webcontent/IndexKepuasan.vue";
import Sejarah from "../views/webcontent/Sejarah.vue";

import Timeline from "../views/Timeline.vue";
import Komunitas from "../views/Komunitas.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    props: true,
    component: Login,
    meta: {
      isHome: 0,
    },
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    props: true,
    component: PrivacyPolicy,
    meta: {
      isHome: 0,
    },
  },
  {
    path: "/privacymobile",
    name: "PrivacyMobile",
    props: true,
    component: PrivacyMobile,
    meta: {
      isHome: 0,
    },
  },
  {
    path: "/pers",
    name: "Pers",
    props: true,
    component: Pers,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/pers-add",
    name: "PersAdd",
    props: true,
    component: PersAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/pers-edit/:id",
    name: "PersEdit",
    props: true,
    component: PersEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/nakes",
    name: "Nakes",
    props: true,
    component: Nakes,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/nakes-add",
    name: "NakesAdd",
    props: true,
    component: NakesAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/nakes-edit/:id",
    name: "NakesEdit",
    props: true,
    component: NakesEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    props: true,
    component: Dashboard,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/kegiatan",
    name: "Kegiatan",
    props: true,
    component: Kegiatan,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/kegiatan-add",
    name: "KegiatanAdd",
    props: true,
    component: KegiatanAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/kegiatan-edit/:id",
    name: "KegiatanEdit",
    props: true,
    component: KegiatanEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/report/kegiatan/:kegiatanId/user/:id",
    name: "report",
    props: true,
    component: Report,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/user",
    name: "User",
    props: true,
    component: User,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/user-add",
    name: "UserAdd",
    props: true,
    component: UserAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/user-edit/:id",
    name: "UserEdit",
    props: true,
    component: UserEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/profesi",
    name: "Profesi",
    props: true,
    component: Profesi,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/wilayah",
    name: "Wilayah",
    props: true,
    component: Wilayah,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/kabupaten/:id",
    name: "Kabupaten",
    props: true,
    component: Kabupaten,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/spesialisasi",
    name: "Spesialisasi",
    props: true,
    component: Spesialisasi,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/bank",
    name: "Bank",
    props: true,
    component: Bank,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/evaluasi-fasilitator",
    name: "Evaluasi Fasilitator",
    props: true,
    component: EvaluasiFasilitator,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/layanan",
    name: "Layanan",
    props: true,
    component: Layanan,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/faskes",
    name: "Faskes",
    props: true,
    component: Faskes,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/faskes/nakes/:id",
    name: "FaskesNakes",
    props: true,
    component: FaskesNakes,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/faskes-category",
    name: "FaskesCategory",
    props: true,
    component: FaskesCategory,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/tentang-kami",
    name: "TentangKami",
    props: true,
    component: TentangKami,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/kontak-kami",
    name: "KontakKami",
    props: true,
    component: KontakKami,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/syarat-ketentuan",
    name: "SyaratKetentuan",
    props: true,
    component: SyaratKetentuan,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/bantuan",
    name: "Bantuan",
    props: true,
    component: Bantuan,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/privacy",
    name: "Privacy",
    props: true,
    component: Privacy,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/chats",
    name: "Chats",
    props: true,
    component: Chats,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/chats/list/:nakes_id/:patient_id",
    name: "ChatsList",
    props: true,
    component: ChatsList,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/brosurlist",
    name: "BrosurList",
    props: true,
    component: BrosurList,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/jadwalpelatihanlist",
    name: "JadwalPelatihanList",
    props: true,
    component: JadwalPelatihanList,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/agenda-pelatihan",
    name: "AgendaPelatihan",
    props: true,
    component: AgendaPelatihan,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/master/jabatanlist",
    name: "JabatanList",
    props: true,
    component: Jabatan,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/direksi",
    name: "DireksiList",
    props: true,
    component: Direksi,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/berita",
    name: "BeritaList",
    props: true,
    component: Berita,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/testimoni",
    name: "TestimoniList",
    props: true,
    component: Testimoni,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/fasilitas",
    name: "FasilitasList",
    props: true,
    component: Fasilitas,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/fasilitator",
    name: "FasilitatorList",
    props: true,
    component: Fasilitator,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/struktur-organisasi",
    name: "StrukturOrganisasi",
    props: true,
    component: StrukturOrganisasi,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/visi-misi",
    name: "VisiMisi",
    props: true,
    component: VisiMisi,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/alur-pendaftaran",
    name: "AlurPendaftaran",
    props: true,
    component: AlurPendaftaran,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/index-kepuasan",
    name: "IndexKepuasan",
    props: true,
    component: IndexKepuasan,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/sejarah",
    name: "Sejarah",
    props: true,
    component: Sejarah,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/timeline",
    name: "Timeline",
    props: true,
    component: Timeline,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/komunitas",
    name: "Komunitas",
    props: true,
    component: Komunitas,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/master/profesi",
    name: "Profesi",
    props: true,
    component: Profesi,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    console.log("masuk sini");
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/");
  } else {
    console.log("masuk 2");
    next();
  }
});

export default router;
