<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Jadwal Pelatihan
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari jadwal"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer>

          <v-btn color="purple" dark class="mb-2" @click="doAdd">
            Tambah
          </v-btn>

          <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Jadwal Pelatihan</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Judul"
                        required
                        v-model="currentData.judul"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-file-input
                        label="File"
                        v-model="currentData.file_url"
                        accept="application/pdf"
                        prepend-icon="mdi-file-document"
                        @change="changeFile"
                        show-size
                        dense
                        placeholder="File"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        :key="currentData.fileKey"
                        label="Nama File"
                        v-model="currentData.file_name"
                        maxlength="200"
                        dense
                        placeholder="Nama File"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:[`item.file_name`]="{ item }">
            <a
              v-if="item.file_url_original"
              :href="item.file_url"
              target="_blank"
              >{{ item.file_name }}</a
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import JadwalPelatihanService from "../../services/web_content/JadwalPelatihanService";
import CommonService from "../../services/CommonService";

export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Data Jadwal Pelatihan",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Judul", value: "judul", sortable: false },
      { text: "File", value: "file_name", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      id: null,
      judul: "",
      file_url: null,
      file_name: "",
      fileKey: 0,
    },
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      JadwalPelatihanService.jadwalPelatihanList(
        page,
        itemsPerPage,
        this.search
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function() {
      this.dialog = true;
      this.currentData.id = null;
      this.currentData.judul = "";
      this.currentData.file_url = null;
      this.currentData.file_name = "";
      console.log("do add");
    },

    doSave: async function() {
      if (this.currentData.id != null) {
        JadwalPelatihanService.update(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Update jadwal pelatihan berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Update jadwal pelatihan gagal. Coba lagi"
            );
          });
      } else {
        JadwalPelatihanService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Tambah jadwal pelatihan berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Tambah jadwal pelatihan gagal. Coba lagi"
            );
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.judul = this.editedItem.judul;
      this.currentData.file_name = this.editedItem.file_name;
      this.currentData.id = this.editedItem.id;
      console.log("do edit");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      JadwalPelatihanService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapur jadwal pelatihan berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast(
            "Hapur jadwal pelatihan gagal. Coba lagi"
          );
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    changeFile: function(file) {
      console.log("changeMateriFile:", file);
      console.log(file.name);
      this.currentData.file_name = file.name;
      this.currentData.fileKey++;
    },
  },
};
</script>
