<template>
  <div>
    <v-card>
      <v-card-title> Pelatihan <br /> </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        :single-select="true"
        show-select
        class="elevation-1"
      >
        <template v-slot:[`item.tanggal`]="{ item }">
          {{ getCurrentDate(item.tanggal) }}
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mt-4">
      <PelatihanPesertaPostTestComponent
        v-if="selected != undefined && selected.length > 0"
        :id="selected[0].id"
        :kegiatanId="kegiatanId"
        :pelatihanDetailId="selected[0].pelatihan_detail_id"
        ref="mychild"
        :key="childKey"
      />
    </v-card>
  </div>
</template>

<script>
import ReportService from "../../services/ReportService";
import * as moment from "moment";
import CommonService from "../../services/CommonService";
import PelatihanPesertaPostTestComponent from "./PelatihanPesertaPostTest.vue";

export default {
  props: {
    id: String,
    kegiatanId: String,
  },
  components: {
    PelatihanPesertaPostTestComponent,
  },
  data: () => ({
    childKey: 0,
    isEditable: 0,
    valid: true,
    tab: null,
    search: "",
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Judul", value: "title", sortable: false },
      { text: "Tanggal", value: "tanggal", sortable: false },
      { text: "Jam Awal", value: "jam_awal", sortable: false },
      { text: "Jam Akhir", value: "jam_akhir", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentFaskes: {
      nama: "",
    },

    currentData: {
      kegiatan_id: "",
      id: null,
      title: "",
      tanggal: "",
      jam_awal: "",
      jam_akhir: "",
      titleRules: [(v) => !!v || "Judul harus diisi"],
      tanggalRules: [(v) => !!v || "Tanggal harus diisi"],
      jamAwalRules: [(v) => !!v || "Jam Awal harus diisi"],
      jamAkhirRules: [(v) => !!v || "Jam Akhir harus diisi"],
    },

    layananList: [],
    currentLayanan: null,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      ReportService.pelatihanPosttestList(
        page,
        itemsPerPage,
        this.search,
        this.id,
        this.kegiatanId
      )
        .then((res) => {
          this.list_data = res.data.list_data;

          if (this.list_data.length > 0) {
            this.$nextTick(() => {
              this.selected.push(this.list_data[0]);
              this.childKey++;
            });
          }
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    openTambah() {
      this.currentData.id = null;
      this.dialog = true;
      this.isEditable = 1;
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      console.log(this.editedItem);
      this.currentData = this.editedItem;
      this.currentData.tanggal = moment(this.editedItem.tanggal).format(
        "YYYY-MM-DD"
      );

      this.isEditable = 1;
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id == null) {
        this.currentData.kegiatan_id = this.id;
        ReportService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah pelatihan berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showSuccessToast("Tambah pelatihan gagal. Coba lagi");
          });
      } else {
        this.currentData.kegiatan_id = this.id;

        ReportService.update(this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);

            CommonService.showSuccessToast("Update pelatihan berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showSuccessToast("Update pelatihan gagal. Coba lagi");
          });
      }
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      ReportService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus pelatihan berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Hapus pelatihan gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
