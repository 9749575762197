import { render, staticRenderFns } from "./PelatihanEvaluasiPenyelenggaraanReport.vue?vue&type=template&id=d8c7d84c&scoped=true&"
import script from "./PelatihanEvaluasiPenyelenggaraanReport.vue?vue&type=script&lang=js&"
export * from "./PelatihanEvaluasiPenyelenggaraanReport.vue?vue&type=script&lang=js&"
import style0 from "./PelatihanEvaluasiPenyelenggaraanReport.vue?vue&type=style&index=0&id=d8c7d84c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8c7d84c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardTitle,VDataTable,VSpacer})
