<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-tabs v-model="tab">
          <v-tab key="download">Download</v-tab>
          <v-tab key="pretest">Pre Test</v-tab>
          <v-tab key="posttest">Post Test</v-tab>
          <!-- <v-tab key="fasilitator">Evaluasi Fasilitator</v-tab> -->
          <!-- <v-tab key="penyelenggaraan">Evaluasi Penyelenggaraan</v-tab> -->
          <!-- <v-tab key="pasca">Evaluasi Pasca Kegiatan</v-tab> -->
          <!-- <v-tab key="rtl">Evaluasi Rencana Tindak Lanjut</v-tab> -->
        </v-tabs>
        <v-tabs-items v-model="tab" touchless>
          <v-tab-item key="download">
            <LaporanComponent :id="id" :kegiatanId="kegiatanId" />
          </v-tab-item>
          <v-tab-item key="pretest">
            <PelatihanReportResultPretestComponent
              :id="id"
              :kegiatanId="kegiatanId"
            />
          </v-tab-item>
          <v-tab-item key="posttest">
            <PelatihanReportResultPosttestComponent
              :id="id"
              :kegiatanId="kegiatanId"
            />
          </v-tab-item>
          <v-tab-item key="fasilitator">
            <PelatihanEvaluasiFasilitatorComponent
              :id="id"
              :kegiatanId="kegiatanId"
              :userId="userId"
            />
          </v-tab-item>
          <v-tab-item key="penyelenggaraan">
            <PelatihanEvaluasiPenyelenggaraanComponent
              :id="id"
              :kegiatanId="kegiatanId"
              :userId="userId"
            />
          </v-tab-item>
          <v-tab-item key="pasca">
            <PelatihanEvaluasiPascaKegiatanComponent
              :id="id"
              :kegiatanId="kegiatanId"
              :userId="userId"
            />
          </v-tab-item>
          <v-tab-item key="rtl">
            <PelatihanEvaluasiRTLComponent
              :id="id"
              :kegiatanId="kegiatanId"
              :userId="userId"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import CommonService from "../../services/CommonService";
import KegiatanService from "../../services/KegiatanService";
import MasterService from "../../services/MasterService";
import * as moment from "moment";
import PelatihanReportResultPosttestComponent from "../report_result/PelatihanReportResultPosttest.vue";
import PelatihanEvaluasiFasilitatorComponent from "../report/PelatihanNarasumberReport.vue";
import PelatihanEvaluasiPenyelenggaraanComponent from "../report/PelatihanEvaluasiPenyelenggaraanReport.vue";
import PelatihanEvaluasiPascaKegiatanComponent from "../report/PelatihanEvaluasiPascaKegiatanReport.vue";
import PelatihanEvaluasiRTLComponent from "../report/PelatihanEvaluasiRTLReport.vue";

import PelatihanReportResultPretestComponent from "../report_result/PelatihanReportResultPretest.vue";
import LaporanComponent from "../report/Laporan.vue";

export default {
  name: "DataReport",
  components: {
    PelatihanReportResultPosttestComponent,
    PelatihanEvaluasiPenyelenggaraanComponent,
    PelatihanEvaluasiPascaKegiatanComponent,
    PelatihanEvaluasiFasilitatorComponent,
    PelatihanEvaluasiRTLComponent,
    PelatihanReportResultPretestComponent,
    LaporanComponent,
  },
  props: {
    id: String,
    kegiatanId: String,
    userId: String,
  },
  data: () => ({
    tab: null,
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Report",
        disabled: false,
        exact: true,
        to: { name: "Report" },
      },
      { text: "Detil Report", disabled: true },
    ],
    valid: true,
    currentData: {
      FotoUrl: "",
      file_foto: null,
      kegiatan_id: "",
      kegiatan_title: "",
      kegiatan_desc: "",
      informasi: "",
      prasyarat_khusus: "",
      harga: "",
      tanggal_awal: "",
      tanggal_akhir: "",
      tanggal_evaluasi_pasca_kegiatan: "",
      jenis_kegiatan: "",
      skp_narasumber: "",
      skp_moderator: "",
      skp_panitia: "",
      skp_peserta: "",
      nomor_skp: "",
      penyelenggara: "",
      kategori: "",
      selectedKategori: "",
      kegiatanTitleRules: [(v) => !!v || "Judul harus diisi"],
      kegiatanDescRules: [(v) => !!v || "Deskripsi harus diisi"],
    },
    items_kategori: [],
    fotoKey: 0,
  }),
  mounted: async function () {
    this.getListKategoriKegiatan();
    this.getKegiatan();
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
  },
  methods: {
    changeEditable: function () {
      this.isEditable = 1;
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },

    getKegiatan() {
      KegiatanService.getKegiatan(this.id)
        .then((res) => {
          console.log(res.data.kegiatan);
          this.currentData = res.data.kegiatan;
          this.currentData.tanggal_awal = moment(
            this.currentData.tanggal_awal
          ).format("YYYY-MM-DD");
          this.currentData.tanggal_akhir = moment(
            this.currentData.tanggal_akhir
          ).format("YYYY-MM-DD");

          if (this.currentData.tanggal_evaluasi_pasca_kegiatan) {
            this.currentData.tanggal_evaluasi_pasca_kegiatan = moment(
              this.currentData.tanggal_evaluasi_pasca_kegiatan
            ).format("YYYY-MM-DD");
          }

          if (res.data.kegiatan.kategori) {
            this.items_kategori.forEach((entry) => {
              if (entry.code == res.data.kegiatan.kategori) {
                this.currentData.selectedKategori = entry;
              }
            });
          }
          this.currentData.FotoUrl = res.data.kegiatan.photo;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKategoriKegiatan() {
      MasterService.kategoriKegiatanList()
        .then((res) => {
          this.items_kategori = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.isEditable = 0;
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      KegiatanService.update(
        this.id,
        this.currentData,
        this.currentData.file_foto
      )
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Edit Kegiatan berhasil");

          this.isEditable = 0;
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Edit Kegiatan gagal. Coba lagi");
        });
    },
  },
};
</script>

<style scoped></style>
