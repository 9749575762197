<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> User </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="6" md="6" class="pr-4">
              <v-sheet color="white" elevation="2">
                <v-responsive :aspect-ratio="16 / 9">
                  <v-img :key="fotoKey" :src="userData.FotoUrl" contain></v-img>
                </v-responsive>
              </v-sheet>
              <v-file-input
                @change="loadImage"
                accept="image/*"
                v-model="userData.file_foto"
                prepend-icon="mdi-image"
                show-size
                label="Foto"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Gelar Depan (Akademik)"
                    outlined
                    dense
                    v-model="userData.gelar_depan"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Nama"
                    outlined
                    dense
                    v-model="userData.nama"
                    required
                    :rules="userData.namaRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Gelar Belakang"
                    outlined
                    dense
                    v-model="userData.gelar_belakang"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="No Anggota"
                    outlined
                    dense
                    v-model="userData.no_anggota"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="No Telepon"
                    outlined
                    dense
                    v-model="userData.phone"
                    :rules="userData.phoneRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Email"
                    outlined
                    dense
                    v-model="userData.email"
                    :rules="userData.emailRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row dense>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-combobox
                    label="Profesi"
                    clearable
                    dense
                    outlined
                    v-model="selectedProfesi"
                    :items="items_profesi"
                    item-text="value"
                  ></v-combobox>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-combobox
                    label="Pendidikan"
                    clearable
                    dense
                    outlined
                    v-model="selectedPendidikan"
                    :items="items_pendidikan"
                    item-text="value"
                  ></v-combobox>
                </v-col>
              </v-row> -->
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="STR"
                    outlined
                    dense
                    v-model="userData.str"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-combobox
                    label="Profesi"
                    clearable
                    dense
                    outlined
                    v-model="userData.selectedProfesi"
                    :items="items_profesi"
                    item-text="value"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-combobox
                    label="Profesi Lainnya"
                    clearable
                    dense
                    outlined
                    v-model="userData.selectedProfesiLainnya"
                    :items="items_profesi"
                    item-text="value"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Masa Berlaku STR"
                    v-model="userData.masa_berlaku"
                    type="date"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="NIP / NRP"
                    outlined
                    dense
                    v-model="userData.nip_nrp"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <h5>Personal Information</h5>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-combobox
                    label="Jenis Kelamin"
                    clearable
                    dense
                    outlined
                    v-model="userData.selectedJenisKelamin"
                    :items="items_jenis_kelamin"
                    item-text="value"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-combobox
                    label="Status Pegawai"
                    clearable
                    dense
                    outlined
                    v-model="userData.selectedStatusPegawai"
                    :items="items_status_pegawai"
                    item-text="value"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-combobox
                    label="Nama Golongan"
                    clearable
                    dense
                    outlined
                    v-model="userData.selectedGolonganPegawai"
                    :items="items_golongan_pegawai"
                    item-text="value"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-combobox
                    label="Agama"
                    clearable
                    dense
                    outlined
                    v-model="userData.selectedAgama"
                    :items="items_agama"
                    item-text="value"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-combobox
                    :key="komunitas_key"
                    label="Komunitas"
                    clearable
                    dense
                    outlined
                    v-model="userData.selectedCommunity"
                    :items="items_community"
                    item-text="name"
                    @click="getListCommunity"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="No KTP"
                    outlined
                    dense
                    v-model="userData.no_ktp"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Tempat Lahir"
                    outlined
                    dense
                    v-model="userData.tempat_lahir"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Tanggal Lahir"
                    v-model="userData.tanggal_lahir"
                    type="date"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-combobox
                    label="Propinsi"
                    clearable
                    dense
                    outlined
                    v-model="userData.selectedPropinsi"
                    :items="items_propinsi"
                    item-text="nama"
                    @change="changePropinsi"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-combobox
                    label="Kabupaten/Kota"
                    clearable
                    dense
                    outlined
                    v-model="userData.selectedKabupaten"
                    :items="items_kabupaten"
                    item-text="nama"
                    @click="getListKabupaten"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Alamat Lengkap"
                    outlined
                    dense
                    v-model="userData.address"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>

      <!-- Modal -->
      <v-dialog v-model="dialogFaskes" max-width="600px">
        <v-card>
          <v-card-title>
            Faskes
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchNakes"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              hide-details
              v-on:keyup.enter="getNakesFromApi"
            ></v-text-field>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="list_data"
            :options.sync="options"
            :server-items-length="totalData"
            :loading="loading"
            class="elevation-1"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                class="ma-2"
                color="primary"
                dark
                @click="pilihFaskes(item)"
              >
                Pilih
                <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogFaskes = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import NakesService from "../services/NakesService";
import FaskesService from "../services/FaskesService";
import CommonService from "../services/CommonService";
import MasterService from "../services/MasterService";

export default {
  name: "NakesAdd",
  components: {},
  data: () => ({
    komunitas_key: 0,
    breadcrumbs: [
      {
        text: "Data User",
        disabled: false,
        exact: true,
        to: { name: "Nakes" },
      },
      { text: "Tambah User", disabled: true },
    ],
    valid: true,
    userData: {
      FotoUrl: "",
      file_foto: null,
      gelar_depan: "",
      nama: "",
      gelar_belakang: "",
      email: "",
      phone: "",
      profesi_id: "",
      str: "",
      no_ktp: "",
      nip_nrp: "",
      tempat_lahir: "",
      selectedProfesi: "",
      selectedProfesiLainnya: "",
      selectedJenisKelamin: "",
      selectedStatusPegawai: "",
      selectedGolonganPegawai: "",
      selectedAgama: "",
      selectedCommunity: "",
      selectedPropinsi: "",
      selectedKabupaten: "",
      no_anggota: "",
      tanggal_lahir: "",
      address: "",
      masa_berlaku: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      phoneRules: [(v) => !!v || "No Telepon harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
      noAnggotaRules: [(v) => !!v || "Email harus diisi"],
    },
    items_profesi: [],
    items_jenis_kelamin: [],
    items_status_pegawai: [],
    items_golongan_pegawai: [],
    items_agama: [],
    items_community: [],
    items_propinsi: [],
    items_kabupaten: [],
    result: {},
    fotoKey: 0,
    currentFaskes: {
      id: "",
      nama: "",
    },
    dialogFaskes: false,
    searchNakes: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Kategori", value: "category_name", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },
  }),
  mounted: async function () {
    this.getListPropinsi();
    this.getListKabupaten();
    this.getListProfesi();
    this.getListJenisKelamin();
    this.getListStatusPegawai();
    this.getListGolonganPegawai();
    this.getListAgama();
  },
  computed: {
    imageSrc() {
      return this.userData.FotoUrl;
    },
  },
  watch: {
    options: {
      handler() {
        this.getNakesFromApi();
      },
      deep: true,
    },
  },
  methods: {
    pilihFaskes: function (item) {
      console.log(item);
      this.currentFaskes.id = item.id;
      this.currentFaskes.nama = item.nama;
      this.userData.faskes_id = item.id;

      this.dialogFaskes = false;
    },

    clearCurrentFakes: function () {
      this.currentFaskes.id = "";
      this.currentFaskes.nama = "";
      this.userData.faskes_id = "";

      console.log(this.currentFaskes.id);
    },

    getNakesFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      FaskesService.faskesList(page, itemsPerPage, this.searchNakes)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    openDialogFaskes: function () {
      this.dialogFaskes = !this.dialogFaskes;

      if (this.dialogFaskes) {
        this.getNakesFromApi();
        this.options.page = 1;
      }
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.userData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.userData.FotoUrl = "";
        this.userData.file_foto = null;
      }
    },

    async getListProfesi() {
      NakesService.profesiList()
        .then((res) => {
          this.items_profesi = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListJenisKelamin() {
      NakesService.jenisKelaminList()
        .then((res) => {
          this.items_jenis_kelamin = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListStatusPegawai() {
      MasterService.statusPekerjaanList()
        .then((res) => {
          this.items_status_pegawai = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListGolonganPegawai() {
      MasterService.golonganPekerjaanList()
        .then((res) => {
          this.items_golongan_pegawai = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListAgama() {
      MasterService.agamaList()
        .then((res) => {
          this.items_agama = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async changePropinsi() {
      this.userData.selectedKabupaten = null;
    },

    async getListKabupaten() {
      NakesService.kabupatenList(this.userData.selectedPropinsi.id)
        .then((res) => {
          this.items_kabupaten = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListCommunity() {
      NakesService.communityList(this.userData.selectedProfesi.code)
        .then((res) => {
          this.items_community = res.data.list_data;
          this.$nextTick(() => {
            this.userData.komunitas_key++;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go(-1);
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      NakesService.create(this.userData, this.userData.file_foto)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Tambah Nakes berhasil");

          this.$router.push("/nakes-edit/" + res.data.user.id).catch((err) => {
            console.log(err);
          });
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Tambah Nakes gagal. Coba lagi");
        });
    },
  },
};
</script>

<style scoped>
</style>
