<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Pelatihan
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Nama"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer>

          <router-link
            to="/kegiatan-add"
            style="text-decoration: none; color: inherit"
          >
            <v-btn color="purple" dark class="mb-2"> Tambah </v-btn>
          </router-link>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogPublish" max-width="500px">
            <v-card>
              <v-card-title v-if="editedItem.data_status == 2" class="text-h5"
                >Apa anda akan mem-publish data ini?</v-card-title
              >
              <v-card-title v-if="editedItem.data_status == 1" class="text-h5"
                >Apa anda akan mem-unpublish data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closePublish"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="publishItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:[`item.tanggal_awal`]="{ item }">
            {{ getCurrentDate(item.tanggal_awal) }}
          </template>
          <template v-slot:[`item.tanggal_akhir`]="{ item }">
            {{ getCurrentDate(item.tanggal_akhir) }}
          </template>
          <template v-slot:[`item.harga`]="{ item }">
            {{ new Intl.NumberFormat("id-ID").format(item.harga) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <v-icon medium class="mr-3" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon medium class="mr-3" @click="publishItem(item)">
                mdi-more
              </v-icon>
              <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
            </div>
          </template>

          <template v-slot:[`item.data_status`]="{ item }">
            <v-chip :color="getColor(item.data_status)" dark>
              {{ getIsHide(item.data_status) }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import KegiatanService from "../services/KegiatanService";
import * as moment from "moment";

export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Data Pelatihan",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Judul", value: "kegiatan_title", sortable: false },
      { text: "Kategori", value: "kategori_name", sortable: false },
      { text: "Tanggal Awal", value: "tanggal_awal", sortable: false },
      { text: "Tanggal Akhir", value: "tanggal_akhir", sortable: false },
      { text: "Harga", value: "harga", sortable: false },
      { text: "Status", value: "data_status", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    dialogPublish: false,
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  computed: {},
  methods: {
    getIsHide(dataStatus) {
      console.log(dataStatus);
      if (dataStatus == 1) {
        return "Published";
      } else {
        return "Unpublished";
      }
    },

    getColor(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 0:
          return "red";
        case 1:
          return "cyan";
        case 2:
          return "red";
        default:
          return "red";
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      KegiatanService.kegiatanList(page, itemsPerPage, this.search)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/kegiatan-edit/" + item.kegiatan_id).catch((err) => {
        console.log(err);
      });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    publishItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogPublish = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      KegiatanService.delete(this.editedItem.kegiatan_id)
        .then((res) => {
          console.log(res);
          this.$toast.success("Hapus kegiatan berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Hapus kegiatan gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closePublish() {
      this.dialogPublish = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    publishItemConfirm() {
      KegiatanService.publish(this.editedItem)
        .then((res) => {
          console.log(res);

          var message = "Unpublish pelatihan berhasil";

          console.log(this.editedItem.data_status);
          if (this.editedItem.data_status == 2) {
            message = "Publish pelatihan berhasil";
          }

          this.$toast.success(message, {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.closePublish();

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          var message = "Publish pelatihan gagal. Coba lagi";

          if (this.editedItem.data_status == 2) {
            message = "Unpublish pelatihan gagal. Coba lagi";
          }

          this.closePublish();

          this.$toast.error(message, {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
  },
};
</script>
