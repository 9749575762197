<template>
  <v-main>
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <div style="position: relative">
        <v-btn
          v-if="readOnly"
          absolute
          fab
          top
          right
          dark
          color="primary"
          @click="editItem"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
      <br />
      <br />
      <form>
        <v-row dense>
          <v-col cols="12" sm="12" md="12">
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Periode Dari"
                  v-model="currentData.periode_dari"
                  type="date"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Periode Sampai"
                  v-model="currentData.periode_sampai"
                  type="date"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Nilai ex: A"
                  outlined
                  dense
                  v-model="currentData.nilai"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Nilai Penyebut ex: Kepuasan Tinggi"
                  outlined
                  dense
                  v-model="currentData.nilai_sebut"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Jumlah Responden"
                  outlined
                  dense
                  v-model="currentData.jumlah_responden"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="!readOnly">
          <v-col cols="12" class="d-flex justify-end">
            <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
            <v-btn dark color="primary" type="submit" @click.prevent="doSubmit"
              >Simpan</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </v-container>
  </v-main>
</template>

<script>
import IndexKepuasanService from "../../services/web_content/IndexKepuasanService";
import CommonService from "../../services/CommonService";
import * as moment from "moment";

export default {
  name: "IndexKepuasan",
  components: {},
  props: {},
  data: () => ({
    breadcrumbs: [
      {
        text: "Index Kepuasan",
        disabled: false,
        exact: true,
        to: { name: "IndexKepuasan" },
      },
      { text: "Detail", disabled: true },
    ],
    readOnly: true,
    currentData: {
      id: null,
      periode_dari: null,
      poster_url: null,
      description: "",
    },
  }),
  computed: {
    outlined() {
      return this.readOnly;
    },
    hideDetails() {
      return true;
    },
  },
  mounted: async function () {
    this.fetch();
  },
  methods: {
    fetch: async function () {
      try {
        IndexKepuasanService.indexKepuasan()
          .then((res) => {
            if (res.data.index_kepuasan) {
              this.currentData.id = res.data.index_kepuasan.id;

              if (res.data.index_kepuasan.periode_dari) {
                this.currentData.periode_dari = moment(
                  res.data.index_kepuasan.periode_dari
                ).format("YYYY-MM-DD");
              }

              if (res.data.index_kepuasan.periode_sampai) {
                this.currentData.periode_sampai = moment(
                  res.data.index_kepuasan.periode_sampai
                ).format("YYYY-MM-DD");
              }

              this.currentData.nilai = res.data.index_kepuasan.nilai;
              this.currentData.nilai_sebut =
                res.data.index_kepuasan.nilai_sebut;
              this.currentData.jumlah_responden =
                res.data.index_kepuasan.jumlah_responden;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (e) {
        console.error("error:", e);
      }
    },

    editItem: async function () {
      this.readOnly = false;
    },

    doBatal: function () {
      this.readOnly = true;
    },

    doSubmit: async function () {
      try {
        if (this.currentData.id != null) {
          IndexKepuasanService.update(this.currentData)
            .then((res) => {
              console.log(res);
              CommonService.showSuccessToast("Update index kepuasan berhasil");

              this.fetch();
              this.readOnly = true;
            })
            .catch((err) => {
              console.log(err);
              CommonService.showErrorToast(
                "Update index kepuasan gagal. Coba lagi"
              );
            });
        } else {
          IndexKepuasanService.create(this.currentData)
            .then((res) => {
              console.log(res);
              this.dialog = false;
              CommonService.showSuccessToast("Tambah index kepuasan berhasil");

              this.getDataFromApi();
              this.readOnly = true;
            })
            .catch((err) => {
              console.log(err);
              CommonService.showErrorToast(
                "Tambah index kepuasan gagal. Coba lagi"
              );
            });
        }
      } catch (e) {
        console.error("error:", e);
      }
    },
  },
};
</script>

<style scoped>
</style>
