<template>
  <div>
    <v-card>
      <v-card-title>
        Evaluasi Rencana Tindak Lanjut <br />

        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
        :hide-default-footer="true"
        :single-expand="false"
        :expanded.sync="expanded"
        show-expand
      >
        <template v-slot:[`item.tanggal`]="{ item }">
          {{ getCurrentDate(item.tanggal) }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <tr>
            <td :colspan="headers.length" class="pt-4 pb-4 pl-4">
              <span>Sasaran : </span> <br />
              <span class="content-desc">{{ item.sasaran }}</span>
            </td>
          </tr>
          <tr>
            <td :colspan="headers.length" class="pt-4 pb-4 pl-4">
              <span>Keterangan : </span> <br />
              <span class="content-desc">{{ item.keterangan }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <!-- Modal -->
  </div>
</template>

<script>
import ReportService from "../../services/ReportService";
import * as moment from "moment";

export default {
  props: {
    id: Number,
    kegiatanId: String,
    userId: String,
  },
  data: () => ({
    expanded: [],
    childKey: 0,
    isEditable: 0,
    valid: true,
    tab: null,
    search: "",
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Kegiatan", value: "title", sortable: false },
      { text: "Waktu Pelaksanaan", value: "tanggal", sortable: false },
      { text: "Jam Awal", value: "jam_awal", sortable: false },
      { text: "Jam Akhir", value: "jam_akhir", sortable: false },
      { text: "Sasaran", value: "data-table-expand" },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentFaskes: {
      nama: "",
    },

    currentData: {
      kegiatan_id: "",
      id: null,
      aspek_dinilai: "",
      titleRules: [(v) => !!v || "Aspek harus diisi"],
      tanggalRules: [(v) => !!v || "Tanggal harus diisi"],
      jamAwalRules: [(v) => !!v || "Jam Awal harus diisi"],
      jamAkhirRules: [(v) => !!v || "Jam Akhir harus diisi"],
    },

    layananList: [],
    currentLayanan: null,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      ReportService.evaluasiRTLReportList(
        page,
        itemsPerPage,
        this.search,
        this.kegiatanId,
        this.userId
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.content-desc {
  white-space: pre-wrap;
  font-size: 14px;
}
</style>
