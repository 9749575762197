<template>
  <div>
    <v-card>
      <v-card-title>
        Evaluasi Pasca Kegiatan <br />
        <v-spacer></v-spacer>
      </v-card-title>

      <div>
        <v-row dense class="pl-4 pr-4">
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="searchPeserta"
              append-icon="mdi-magnify"
              label="Cari Peserta"
              single-line
              hide-details
              class="pl-4 pr-4"
              v-on:keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-combobox
              label="Status"
              clearable
              dense
              outlined
              v-model="selectedStatus"
              :items="items_status"
              item-text="value"
              @change="changeStatus"
            ></v-combobox>
          </v-col>
        </v-row>
      </div>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        item-key="no"
        :single-select="true"
        show-select
        class="elevation-1"
      >
        <template v-slot:[`item.tanggal`]="{ item }">
          {{ getCurrentDate(item.tanggal) }}
        </template>

        <template v-slot:[`item.jumlah`]="{ item }">
          <v-chip :color="getColor(item.jumlah)" dark>
            {{ getStatus(item.jumlah) }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>

    <!-- <v-card class="mt-4">
      <PelatihanEvaluasiNarasumberComponent
        v-if="selected != undefined && selected.length > 0"
        :id="selected[0].id"
        ref="mychild"
        :key="childKey"
        :kegiatanId="kegiatanId"
        :userId="id"
      />
    </v-card> -->
  </div>
</template>

<script>
import RecordActivityService from "../../services/RecordActivityService";
import * as moment from "moment";
// import PelatihanEvaluasiNarasumberComponent from "./PelatihanEvaluasiNarasumberReport.vue";

export default {
  props: {
    id: String,
    kegiatanId: String,
  },
  components: {
    // PelatihanEvaluasiNarasumberComponent,
  },
  data: () => ({
    childKey: 0,
    isEditable: 0,
    valid: true,
    tab: null,
    searchNarasumber: "",
    searchPeserta: "",
    selectedStatus: null,
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Peserta", value: "user_nama", sortable: false },
      { text: "Status", value: "jumlah", sortable: false },
      { text: "Tanggal", value: "tanggal", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentFaskes: {
      nama: "",
    },

    currentData: {
      pelatihan_detail_id: "",
      id: null,
      nama: "",
      titleRules: [(v) => !!v || "Nama harus diisi"],
      tanggalRules: [(v) => !!v || "Tanggal harus diisi"],
      jamAwalRules: [(v) => !!v || "Jam Awal harus diisi"],
      jamAkhirRules: [(v) => !!v || "Jam Akhir harus diisi"],
    },

    layananList: [],
    currentLayanan: null,
    userId: null,
    narasumberId: null,
    items_status: [
      {
        id: 1,
        value: "Belum Evaluasi",
      },
      {
        id: 2,
        value: "Sudah Evaluasi",
      },
    ],
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getColor(jumlah) {
      if (jumlah > 0) {
        return "blue";
      } else {
        return "pink";
      }
    },

    getStatus(jumlah) {
      if (jumlah > 0) {
        return "Sudah Evaluasi";
      } else {
        return "Belum Evaluasi";
      }
    },

    getCurrentDate(tanggal) {
      if (tanggal) return moment(tanggal).format("DD MMMM YYYY HH:mm");
      else return "";
    },

    async changeStatus() {
      console.log(this.selectedStatus);
      this.getDataFromApi();
    },

    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var currentStatus = null;

      if (this.selectedStatus) {
        currentStatus = this.selectedStatus.id;
      }

      RecordActivityService.evaluasiPascaKegiatanReportList(
        page,
        itemsPerPage,
        this.id,
        this.userId,
        this.searchPeserta,
        currentStatus
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;

          // if (this.list_data.length > 0) {
          //   this.$nextTick(() => {
          //     this.selected.push(this.list_data[0]);
          //     this.childKey++;
          //   });
          // }
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },
  },
};
</script>
