import http from "../../http-common";
/* eslint-disable */
class AlurPendaftaranService {
  alurPendaftaran() {
    return http.get("/alur-pendaftaran", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("account", data.account);
    bodyFormData.append("non_account", data.non_account);

    return http.post("/alur-pendaftaran", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    bodyFormData.append("account", data.account);
    bodyFormData.append("non_account", data.non_account);

    return http.put(`/alur-pendaftaran/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new AlurPendaftaranService();
