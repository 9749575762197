<template>
  <div>
    <v-card>
      <v-card-title>
        Komentar <br />

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari Komentar"
          single-line
          hide-details
          v-on:keyup.enter="getDataFromApi"
        ></v-text-field>
        <v-spacer></v-spacer>

        <!-- <v-btn
          color="purple"
          dark
          class="mb-2"
          @click="openTambah"
        >
          Tambah
        </v-btn> -->
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Pelatihan</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Judul"
                        required
                        v-model="currentData.title"
                        :rules="currentData.titleRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Tanggal"
                        v-model="currentData.tanggal"
                        type="date"
                        dense
                        :rules="currentData.tanggalRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Jam Awal"
                        v-model="currentData.jam_awal"
                        type="time"
                        dense
                        :rules="currentData.jamAwalRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Jam Akhir"
                        v-model="currentData.jam_akhir"
                        type="time"
                        dense
                        :rules="currentData.jamAkhirRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        :single-select="true"
        show-select
        class="elevation-1"
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ getCurrentDate(item.created_at) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import PelatihanKomentarService from "../../services/PelatihanKomentarService";
import * as moment from "moment";
import CommonService from "../../services/CommonService";

export default {
  props: {
    id: String,
  },
  data: () => ({
    childKey: 0,
    isEditable: 0,
    valid: true,
    tab: null,
    search: "",
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Komentar", value: "komentar", sortable: false },
      { text: "User", value: "user_name", sortable: false },
      { text: "Tanggal", value: "created_at", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentFaskes: {
      nama: "",
    },

    currentData: {
      kegiatan_id: "",
      id: null,
      title: "",
      tanggal: "",
      jam_awal: "",
      jam_akhir: "",
      titleRules: [(v) => !!v || "Judul harus diisi"],
      tanggalRules: [(v) => !!v || "Tanggal harus diisi"],
      jamAwalRules: [(v) => !!v || "Jam Awal harus diisi"],
      jamAkhirRules: [(v) => !!v || "Jam Akhir harus diisi"],
    },

    layananList: [],
    currentLayanan: null,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY HH:mm");
    },

    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      PelatihanKomentarService.pelatihanKomentarList(
        page,
        itemsPerPage,
        this.search,
        this.id
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    openTambah() {
      this.currentData.id = null;
      this.dialog = true;
      this.isEditable = 1;
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      console.log(this.editedItem);
      this.currentData = this.editedItem;
      this.currentData.tanggal = moment(this.editedItem.tanggal).format(
        "YYYY-MM-DD"
      );

      this.isEditable = 1;
    },

    doSave: async function() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id == null) {
        this.currentData.kegiatan_id = this.id;
        PelatihanKomentarService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah pelatihan berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showSuccessToast("Tambah pelatihan gagal. Coba lagi");
          });
      } else {
        this.currentData.kegiatan_id = this.id;

        PelatihanKomentarService.update(this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);

            CommonService.showSuccessToast("Update pelatihan berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showSuccessToast("Update pelatihan gagal. Coba lagi");
          });
      }
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      PelatihanKomentarService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus komentar berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus komentar gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
