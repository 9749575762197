<template>
  <v-main>
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <v-card>
        <PelatihanComponent
          :id="id"
          :kegiatanId="kegiatanId"
        />
      </v-card>

    </v-container>
  </v-main>
</template>

<script>
import CommonService from "../../services/CommonService";
import KegiatanService from "../../services/KegiatanService";
import MasterService from "../../services/MasterService";
import * as moment from "moment";
import PelatihanComponent from "../report/PelatihanReport.vue";

export default {
  name: "KegiatanEdit",
  components: {
    PelatihanComponent,
  },
  props: {
    id: String,
    kegiatanId: String,
  },
  data: () => ({
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Report",
        disabled: false,
        exact: true,
        to: { name: "Report" },
      },
      { text: "Report Pelatihan", disabled: true },
    ],
    valid: true,
    currentData: {
      FotoUrl: "",
      file_foto: null,
      kegiatan_id: "",
      kegiatan_title: "",
      kegiatan_desc: "",
      informasi: "",
      prasyarat_khusus: "",
      harga: "",
      tanggal_awal: "",
      tanggal_akhir: "",
      tanggal_evaluasi_pasca_kegiatan: "",
      jenis_kegiatan: "",
      skp_narasumber: "",
      skp_moderator: "",
      skp_panitia: "",
      skp_peserta: "",
      nomor_skp: "",
      penyelenggara: "",
      kategori: "",
      selectedKategori: "",
      kegiatanTitleRules: [(v) => !!v || "Judul harus diisi"],
      kegiatanDescRules: [(v) => !!v || "Deskripsi harus diisi"],
    },
    items_kategori: [],
    fotoKey: 0,
  }),
  mounted: async function () {
    this.getListKategoriKegiatan();
    this.getKegiatan();
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
  },
  methods: {
    changeEditable: function () {
      this.isEditable = 1;
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },

    getKegiatan() {
      KegiatanService.getKegiatan(this.kegiatanId)
        .then((res) => {
          console.log(res.data.kegiatan);
          this.currentData = res.data.kegiatan;
          this.currentData.tanggal_awal = moment(
            this.currentData.tanggal_awal
          ).format("YYYY-MM-DD");
          this.currentData.tanggal_akhir = moment(
            this.currentData.tanggal_akhir
          ).format("YYYY-MM-DD");

          if (this.currentData.tanggal_evaluasi_pasca_kegiatan) {
            this.currentData.tanggal_evaluasi_pasca_kegiatan = moment(
              this.currentData.tanggal_evaluasi_pasca_kegiatan
            ).format("YYYY-MM-DD");
          }

          if (res.data.kegiatan.kategori) {
            this.items_kategori.forEach((entry) => {
              if (entry.code == res.data.kegiatan.kategori) {
                this.currentData.selectedKategori = entry;
              }
            });
          }
          this.currentData.FotoUrl = res.data.kegiatan.photo;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKategoriKegiatan() {
      MasterService.kategoriKegiatanList()
        .then((res) => {
          this.items_kategori = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.isEditable = 0;
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      KegiatanService.update(
        this.id,
        this.currentData,
        this.currentData.file_foto
      )
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Edit Kegiatan berhasil");

          this.isEditable = 0;
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Edit Kegiatan gagal. Coba lagi");
        });
    },
  },
};
</script>

<style scoped>
</style>
