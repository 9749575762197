<template>
  <v-main>
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <div style="position: relative">
        <v-btn
          v-if="readOnly"
          absolute
          fab
          top
          right
          dark
          color="primary"
          @click="editItem"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
      <br />
      <br />
      <form>
        <v-row dense>
          <v-col cols="12" sm="6" md="12">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  rows="8"
                  label="Belum Memiliki Akun"
                  v-model="currentData.non_account"
                  :outlined="outlined"
                  :readonly="readOnly"
                  dense
                  :hide-details="hideDetails"
                  placeholder="Konten"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  rows="8"
                  label="Sudah Memiliki Akun"
                  v-model="currentData.account"
                  :outlined="outlined"
                  :readonly="readOnly"
                  dense
                  :hide-details="hideDetails"
                  placeholder="Konten"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="!readOnly">
          <v-col cols="12" class="d-flex justify-end">
            <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
            <v-btn dark color="primary" type="submit" @click.prevent="doSubmit"
              >Simpan</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </v-container>
  </v-main>
</template>

<script>
import AlurPendaftaranService from "../../services/web_content/AlurPendaftaranService";
import CommonService from "../../services/CommonService";

export default {
  name: "AlurPendaftaran",
  components: {},
  props: {},
  data: () => ({
    breadcrumbs: [
      {
        text: "Alur Pendaftaran Pesertan",
        disabled: false,
        exact: true,
        to: { name: "AlurPendaftaran" },
      },
      { text: "Detail", disabled: true },
    ],
    readOnly: true,
    currentData: {
      id: null,
      account: "",
      non_account: "",
    },
  }),
  computed: {
    outlined() {
      return this.readOnly;
    },
    hideDetails() {
      return true;
    },
  },
  mounted: async function () {
    this.fetch();
  },
  methods: {
    fetch: async function () {
      try {
        console.log("fetch");
        AlurPendaftaranService.alurPendaftaran()
          .then((res) => {
            if (res.data.alur) {
              this.currentData.id = res.data.alur.id;
              this.currentData.account = res.data.alur.account;
              this.currentData.non_account = res.data.alur.non_account;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (e) {
        console.error("error:", e);
      }
    },

    editItem: async function () {
      this.readOnly = false;
    },

    doBatal: function () {
      this.readOnly = true;
    },

    doSubmit: async function () {
      try {
        if (this.currentData.id != null) {
          AlurPendaftaranService.update(this.currentData)
            .then((res) => {
              console.log(res);
              CommonService.showSuccessToast(
                "Update alur pendaftaran peserta berhasil"
              );

              this.fetch();
              this.readOnly = true;
            })
            .catch((err) => {
              console.log(err);
              CommonService.showErrorToast(
                "Update alur pendaftaran peserta gagal. Coba lagi"
              );
            });
        } else {
          AlurPendaftaranService.create(this.currentData)
            .then((res) => {
              console.log(res);
              this.dialog = false;
              CommonService.showSuccessToast(
                "Tambah alur pendaftaran peserta berhasil"
              );

              this.fetch();
              this.readOnly = true;
            })
            .catch((err) => {
              console.log(err);
              CommonService.showErrorToast(
                "Tambah alur pendaftaran peserta gagal. Coba lagi"
              );
            });
        }
      } catch (e) {
        console.error("error:", e);
      }
    },
  },
};
</script>

<style scoped>
</style>
